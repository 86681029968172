Drupal.behaviors.gnavCart = (function ($, _, site, generic) {
  'use strict';

  // Private variables:
  var $blocks = $();
  var $trigger = $();
  var $counters = $();
  var $cartBlock = $();
  var $cartContentBlock = $();
  var $cartImages = $();
  var data = {
    item_count: 0,
    subtotal: '',
    points: 0,
    order_within: '',
    ship_sooner_date: '',
    items: [],
    singular: 0,
    new_items: []
  };
  var state = 'empty';
  var loaded = false;
  var closeNav = false;
  var closeTimeout;
  var counted = false;
  var getCartItemsRPCActive = false;

  /* Replace dots in the top-level key names the server is giving us.
     'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME' */
  function _normalizeResponseKeys(cartItems) {
    var replaceKey = function (key) {
      return key.replace(/\./, '_');
    };
    var engravingCartOverlayNormalizeData =
      Drupal.settings.common.engraving_cart_overlay_normalize_data || false;
    var processMonogram = function (item) {
      var monogramItem = {};

      if (
        Object.prototype.hasOwnProperty.call(item, 'isMonogrammedItem') &&
        item['isMonogrammedItem'] === 1
      ) {
        var engraving_key;
        var engraving_items = item.items[0].engravedData ? item.items[0] : item.items[1];
        var engraving_text = engraving_items.engravedData.engravedText;
        var engraving_motif = engraving_items.engravedData.engravedMotifRight;

        for (engraving_key in engraving_items) {
          monogramItem[replaceKey(engraving_key)] = engraving_items[engraving_key];
          monogramItem['engraving_text'] = engraving_text;
          monogramItem['engraving_motif'] = engraving_motif;
        }
      }

      return monogramItem;
    };
    var processItems = function (items) {
      var processedItems = [];
      var processedItem;

      for (var i in items) {
        processedItem = {};
        if (items[i].isKitItem === 1) {
          processedItems = processedItems.concat(processItems(items[i].items));
          continue;
        }

        for (var key in items[i]) {
          if (Object.prototype.hasOwnProperty.call(items[i], key)) {
            processedItem[replaceKey(key)] = items[i][key];
            if (engravingCartOverlayNormalizeData) {
              Object.assign(processMonogram(items[i]), processedItem);
            }
          }
        }
        if (!$.isEmptyObject(processedItem)) {
          processedItems.push(processedItem);
        }
      }

      return processedItems;
    };

    return processItems(cartItems);
  }

  function _setCloseTimeout() {
    if (state !== 'added') {
      return;
    }
    // @todo make this work properly with aveda gnav
    closeTimeout = setTimeout(function () {
      if (closeNav) {
        // Drupal.behaviors.gnav.close();
        // ghetto, but gets the job done
        $('.js-gnav-util-close').trigger('click');
      }
    }, 5000);
  }

  function _clearCloseTimeout() {
    clearTimeout(closeTimeout);
  }

  // Public methods:
  var behavior = {
    attach: function (context) {
      $trigger = $trigger.add($('.gnav-util--cart', context)).first();
      $blocks = $blocks.add($('.js-gnav-util__content__inner--cart', context));
      $counters = $counters.add($('.gnav-util__icon__cart-count', context));
      // Get the initial item count from the cookie to avoid unnecessary trips
      // to the server.
      this.setData({ item_count: site.userInfoCookie.getValue('item_count') - 0 });
    },

    // Get our variables
    // Give the header block class active to show the contents
    // Listen to a click outside to close it
    open: function () {
      var self = this;

      // If a cartblock exists, its because it was set on click.
      // If not, its being opened programatically and we should assign the cart variable.
      $cartBlock = $cartBlock.length ? $cartBlock : $('.js-gnav-cart');
      // Wherever the cart block is, this is the content block inside of it
      $cartContentBlock = $('.js-gnav-util__content', $cartBlock);
      $('.js-gnav-cart').removeClass('active');
      $cartBlock.addClass('active');
      if (site && site.track) {
        site.track.cartOverlay();
      }
      $('.js-gnav-util__content').off('clickoutside.cart');
      setTimeout(function () {
        // Stop listening to all the carts
        // Listen to an outside click of this cart and close
        $cartContentBlock.on('clickoutside.cart', function () {
          self.close();
        });
      }, 1);
    },

    // Close the header cart blocks
    close: function () {
      $cartBlock.removeClass('active');
      $('.js-gnav-util__content').off('clickoutside.cart');
      behavior.setState();
    },

    getCartItems: function () {
      if (counted || getCartItemsRPCActive) {
        return;
      }
      getCartItemsRPCActive = generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          getCartItemsRPCActive = false;
          var value = response.getValue();

          if (_.isUndefined(value) || !value) {
            return;
          }
          data.item_count = value.items_count;
          behavior.setData({
            item_count: value.items_count
          });
          counted = true;

          return value.items_count;
        },
        onFailure: function () {
          getCartItemsRPCActive = false;
          counted = false;
        }
      });
    },
    refreshOfferCart: function (cartResponse) {
      if (typeof cartResponse === 'undefined' || !cartResponse) {
        return;
      }
      var args = {
        trans: cartResponse,
        items: cartResponse.order.items
      };

      $(document).trigger('offerToCart.refreshCount', args);
    },
    render: function () {
      var rendered = site.template.get({
        name: 'gnav_cart_content',
        data: data
      });

      // Get the updated cart items count
      if (!counted) {
        data.item_count = behavior.getCartItems();
      }
      // Some of the field values may actually contain mustache themselves, so
      // this template needs to be run through rendering a second time.
      rendered = site.template.render(rendered, data);

      $blocks.html(rendered);
      $cartImages = $('img[loading="lazy"]', $blocks);

      $cartImages.each(function () {
        var imgSrc = $(this).attr('data-src');
        $(this).removeClass('lazyload')
          .attr('src', imgSrc)
          .removeAttr('data-src');
      });

      // Update the counters that are outside of the template
      $counters.text(data.item_count);

      // Calculate loyalty points in cart
      site.loyalty.calculatePoints($('.js-pure-privilege', $blocks));

      return this;
    },

    load: function (force) {
      if (loaded && (!_.isBoolean(force) || !force)) {
        return this;
      }

      $blocks.addClass('loading');

      generic.jsonrpc.fetch({
        method: 'trans.get',
        params: [
          {
            trans_fields: ['TRANS_ID', 'totals'],
            payment_fields: [],
            order_fields: ['items', 'samples', 'offerCodes']
          }
        ],
        onSuccess: function (response) {
          $blocks.removeClass('loading');
          var cartItems;
          var value = response.getValue();

          if (Drupal.settings.common.engraving_cart_overlay_normalize_data) {
            var samples = value.order.samples;
            var normalized_samples = [];
            var j = 0;

            for (var i = 0; i < samples.length; i++) {
              if (!samples[i]['engravedData']) {
                normalized_samples[j] = samples[i];
                j++;
              }
            }
            cartItems = value.order.items.concat(normalized_samples);
          } else {
            cartItems = value.order.items.concat(value.order.samples);
          }
          if (_.isUndefined(value) || !value) {
            return;
          }
          behavior.setData({
            subtotal: value.formattedSubtotal,
            points: value.points,
            order_within: '', // This was removed from the designs
            ship_sooner_date: '', // This was removed from the designs
            item_count: value.items_count,
            items: _normalizeResponseKeys(cartItems)
          });
          behavior.setOverlayHeight();
        },
        onError: function () {
          $blocks.removeClass('loading');
          // @TODO: a failure message should go here.
          loaded = false;
        }
      });

      // Don't put loaded in success function! That allows the user to fire
      // additonal requests while the first is still loading.
      loaded = true;

      return this;
    },

    addItem: function (result) {
      if (
        _.isUndefined(result) ||
        !result ||
        _.isUndefined(result.trans_data) ||
        _.isUndefined(result.ac_results)
      ) {
        return this;
      }

      var resultType = this.getResultType(result.ac_results);
      var addedItems = '';

      if (resultType !== 'sku') {
        addedItems = this.setCollection(result);
      } else {
        addedItems = _.map(result.ac_results, function (value) {
          var res = value.result;
          var item = res.CARTITEM;

          // Seems very dumb to calculate this on the front end.
          item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

          if (Drupal.settings.show_taxed_price) {
            var allResults = result;
            var skuBaseID = item['sku.SKU_BASE_ID'];

            if (skuBaseID !== null) {
              var allItems = allResults.trans_data.order.items;

              $.each(allItems, function (i, items) {
                if (items['sku.SKU_BASE_ID'] === skuBaseID) {
                  item.formattedAppliedPrice = items.formattedTaxedAppliedPrice;

                  return false;
                }
              });
            }
          }

          return item;
        });
      }
      this.setData({
        subtotal: result.trans_data.formattedSubtotal,
        points: result.trans_data.points === 0 ? 0 : result.trans_data.points || data.points,
        items: _normalizeResponseKeys(result.trans_data.order.items),
        item_count: result.trans_data.items_count,
        new_items: _normalizeResponseKeys(addedItems)
      });

      // Temporarily set the added state:
      this.setState('added');
      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      closeNav = true;
      // The response after you added to bag contains trans_data.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    addOffer: function (result) {
      if (
        _.isUndefined(result) ||
        !result ||
        _.isUndefined(result.trans) ||
        _.isUndefined(result.items)
      ) {
        return this;
      }
      // var resultType = this.getResultType(result.ac_results);

      // var addedItems = '';
      // addedItems = _.map(result.ac_results, function(value) {
      //   var item = result.items;

      //   // Seems very dumb to calculate this on the front end.
      //   item.new_qty = Math.max(1, item.ITEM_QUANTITY - res.PREVIOUS_ITEM_QUANTITY);

      //   return item;
      // });
      this.setData({
        subtotal: result.trans.formattedSubtotal,
        points: result.trans.points === 0 ? 0 : result.trans.points || data.points,
        items: _normalizeResponseKeys(result.trans.order.items),
        item_count: result.trans.items_count,
        new_items: _normalizeResponseKeys(result.trans.order.samples)
      });

      // Temporarily set the added state:
      this.setState('added');
      // @todo we do not have a global function for gnav
      // @setup new brand - this can be re-enabled if gnav elements need to be bound
      // Drupal.behaviors.gnav.open($trigger);

      closeNav = true;
      // The response after you added to bag contains trans.order.items,
      // which should be your entire cart, so there's no reason to load the cart
      // again:
      loaded = true;
      _setCloseTimeout();

      return this;
    },

    setOverlayHeight: function () {
      var $bagContents = $('.js-cart-block-container'),
        $siteHeader = $('.js-site-header'),
        topPadding = 25,
        overlayFooterHeight = 130;
      /* set height of entire overlay to window height, less gnav offset*/
      var siteHeaderHeight = $siteHeader.outerHeight(true);
      var overlayHeight = $(window).height() - siteHeaderHeight;

      $bagContents.height('auto');
      $bagContents.css('max-height', overlayHeight);
      $bagContents.attr('tabindex', '0').focus();
      site.restrict_navigation($('.js-gnav-cart.active'));

      /* set height of product list to available space*/
      var cartHeaderHeight = $bagContents.find('.js-cart-block-header').outerHeight(true);
      var $cartProductsContainer = $bagContents.find('.js-cart-block-products');
      var productsHeight = overlayHeight - cartHeaderHeight - overlayFooterHeight - topPadding;

      $cartProductsContainer.height('auto');
      $cartProductsContainer.css('max-height', productsHeight);
    },

    // Setters:
    setState: function (newState) {
      var states = ['empty', 'nonempty', 'added'];
      var classPrefix = 'cart-block--';
      var stateClasses = classPrefix + states.join(' ' + classPrefix);

      // If state is undefined, figure it out:
      if (_.isUndefined(newState)) {
        state = data.item_count > 0 ? 'nonempty' : 'empty';
      } else if (!_.contains(states, newState)) {
        // Sanity check:
        throw new Error('"' + newState + '" is not a valid cart state.');
      } else {
        state = newState;
      }

      $blocks.removeClass(stateClasses).addClass(classPrefix + state);
      $trigger.toggleClass('gnav-util--cart--nonempty', state !== 'empty');

      return this;
    },

    setData: function (newData) {
      _.extend(data, newData);
      data.singular = data.item_count === 1;

      this.setState(state).render();

      return this;
    },

    setCollection: function (results) {
      if (!results || !results.ac_results || !results.trans_data) {
        return null;
      }

      var transData = results.trans_data;
      var orderData = transData.order;
      var collectionIds = _.map(results.ac_results, function (obj) {
        var result = obj.result;

        return result && result.COLLECTION_ID && result.CARTITEM ? result.COLLECTION_ID : null;
      });
      var collectionId = _.compact(collectionIds);

      return _.filter(orderData.items, function (item) {
        return _.contains(collectionId, item.COLLECTION_ID);
      });
    },

    // Getters:
    getState: function () {
      return state;
    },

    getData: function (key) {
      return _.isUndefined(key) ? data : data[key];
    },

    getResultType: function (results) {
      var type = 'sku';

      var isCollection = _.filter(results, function (result) {
        return result.instance === 'alter_collection';
      });

      if (isCollection.length > 0) {
        type = 'collection';
      }

      var isReplenishment = _.filter(results, function (result) {
        return result.instance === 'alter_replenishment' && result.type === 'REPL';
      });

      if (isReplenishment.length > 0) {
        type = 'replenishment';
      }

      return type;
    }
  };

  // Document listeners:
  $(document).on('offerToCart.success', function (event, result) {
    behavior.open();
    behavior.addOffer(result);
  });

  $(document).on('offerToCart.refreshCount', function (event, result) {
    behavior.addOffer(result);
  });

  $(document).on('trans.get', function (event) {
    var cartResponse = behavior.getCartItems();

    if (typeof behavior.callback === 'function') {
      behavior.callback(cartResponse);
    }
  });

  // Document listeners:
  $(document).on('addToCart.success', function (event, result) {
    // @setup new brand - this is temporary open/close for testing add to cart
    // a more integrated method with all gnav should be done per brand
    behavior.open();
    behavior.addItem(result);
    if (Drupal.settings.use_trans_get_in_addtocart_success_overlay) {
      // Fetch the trans data to update the overlay.
      loaded = false;
      behavior.load(1);
    }
    behavior.setOverlayHeight();
  });

  // Override preventDefault on gnav overlay logic if cart is empty:
  $(document).on('click', '.js-gnav-util-trigger--cart', function () {
    if (state === 'empty') {
      window.location = $(this).attr('href');

      return true;
    }
  });

  // @TODO -- Do we want to close on clickoutside or mouseleave
  // $(document).on('mouseenter', '.js-cart-block-container', _clearCloseTimeout);
  // $(document).on('mouseleave', '.js-cart-block-container', _setCloseTimeout);

  $(document).on('click', '.cart-block__items__view-bag', function (event) {
    event.preventDefault();

    _clearCloseTimeout();
    behavior.setState().render();
  });

  $(document)
    .on('focus', '.js-gnav-util-trigger--cart', function () {
      var cartLabel = $(this).find('.js-icon--bag').text();
      var closeCartLabel = $(this).find('.js-icon--close').text();

      $(this).attr('aria-label', $(this).parent().hasClass('active') ? closeCartLabel : cartLabel);
    })
    .on('focusout', '.js-gnav-util-trigger--cart', function () {
      $(this).removeClass('custom-outline');
    });

  $(document).on('click', '.js-gnav-util-trigger--cart', function (event) {
    event.preventDefault();
    var cartLabel = $(this).find('.js-icon--bag').text();
    var closeCartLabel = $(this).find('.js-icon--close').text();

    if ($('.gnav-util--cart').length) {
      // Do nothing if the cart is empty:
      if (state === 'empty' || state === 'added') {
        return;
      }
      // Determine to open or not
      if (!$(this).parent().hasClass('active')) {
        // Assign $cartBlock to the clicked add to cart node. This is because there are multiple instances of add to cart in the header.
        $cartBlock = $(this).parent();
        behavior.open();
      } else {
        behavior.close();
      }
      behavior.load();
      behavior.setOverlayHeight();
    }
    $(this).attr(
      'aria-label',
      $(this).attr('aria-label') === cartLabel ? closeCartLabel : cartLabel
    );
    site.restrict_navigation($('.js-gnav-cart.active'));
  });

  $(window).resize(
    _.debounce(function () {
      if ($('.js-gnav-cart').hasClass('active')) {
        behavior.setOverlayHeight();
      }
    }, 250)
  );

  return behavior;
})(
  (window.jQuery = window.jQuery || function () {}),
  (window._ = window._ || {}),
  (window.site = window.site || {}),
  (window.generic = window.generic || {})
);
